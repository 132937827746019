<template>
  <b-container fluid>
    <ValidationObserver ref="serviceGroupObserver" v-slot="{ invalid }">
      <form>
        <b-form-group class="col-md-12" :label="$t('Name')" label-for="name">
          <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
            <b-form-input :class="(errors.length > 0 || nameIsExists == true ?  ' is-invalid' : '')" v-model="model.name" type="text"></b-form-input>
            <b-form-invalid-feedback>{{ nameIsExists == true ? $t('ThisServiceGroupNameAlreadyExists') : $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>

        <b-form-group class="col-md-12" :label="$t('TaxRate')" label-for="vatPercentage">
          <b-form-input v-model="model.vatPercentage" :placeholder="$t('Example18')" @keypress="$root.isNumber"></b-form-input>
        </b-form-group>

        <b-form-group class="col-md-12"
                      label-for="calendarColor"
                      :label="$t('CalendarColor')">
          <v-select v-model="model.calendarColor"
                    :reduce="s => s.id" label="name"
                    :options="colorList">
          <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
        </b-form-group>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewServiceGroup"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import commonServices from '../../../services/common'
  import services from '../../../services/service'

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'

  export default {
    name: 'NewServiceGroup',
    components: {
      ModalFooterButton
    },
    props: {
      serviceGroupId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          vatPercentage: '',
          calendarColor: '#4B77BE'
        },
        colorList: [],
        nameIsExists: false,
        isSubmitting: false
      }
    },
    methods: {
      getColors() {
        commonServices.getColors().then((response) => {
          if (response) {
            this.colorList = response;
          }
        })
      },
      async submitNewServiceGroup() {
        const isValid = await this.$refs.serviceGroupObserver.validate();
        if (!isValid || this.nameIsExists == true) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.isSubmitting = true;

        services.submitNewServiceGroup(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getServiceGroup() {
        services.getServiceGroupForEdit(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      getServiceGroupNameIsExists() {
        services.getServiceGroupNameIsExists(this.model.id, this.model.name)
          .then((response) => {
            this.nameIsExists = response;
          })
      },
    },
    watch: {
      'model.name': function (name) {
        if (name && name.length > 2)
          this.getServiceGroupNameIsExists();
      },
      'model.vatPercentage': function (val) {
        if (val)
          this.model.vatPercentage = val.toString().replace(',', '.');
      }
    },
    mounted() {
      if (this.serviceGroupId && this.serviceGroupId.length > 0) {
        this.model.id = this.serviceGroupId;
        this.getServiceGroup();
      }
      this.getColors();
    }
  }
</script>
