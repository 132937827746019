import axios from './index'

export default {
  submitNewServiceGroup(model) {
    return axios.post('/serviceGroup/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewService(model) {
    return axios.post('/service/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getServiceGroup() {
    return axios.get('/data/entity/serviceGroup')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getServiceGroupForEdit(id) {
    return axios.get('/serviceGroup/getServiceGroupForEdit?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getService(id) {
    return axios.get('/service/getServiceForEdit?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getServiceGroupNameIsExists(id, name) {
    return axios.get('/serviceGroup/serviceGroupNameIsExists?serviceGroupName=' + name + "&serviceGroupId=" + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getServiceNameIsExists(id, name) {
    return axios.get('/service/getServiceNameIsExists?serviceName=' + name + '&serviceId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
